<template>
  <div class="model-view">
    <div v-if="!isShowDetailView" class="model-list">
      <div class="top-bar">
        <div class="tablist">
          <div
            :class="['tab', activeTab == '1' ? 'active-tab' : '']"
            @click="changeTab('1')"
          >
            我的
          </div>
          <div
            :class="['tab', activeTab == '2' ? 'active-tab' : '']"
            @click="changeTab('2')"
          >
            项目组
          </div>
          <div
            :class="['tab', activeTab == '3' ? 'active-tab' : '']"
            @click="changeTab('3')"
          >
            公开
          </div>
        </div>
        <div v-if="activeTab !== '3'" class="select">
          <el-select
            v-model="groupId"
            placeholder="请选择"
            filterable
            @change="handleGroupChange"
          >
            <el-option
              v-for="item in groupUserOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="right">
          <div class="search">
            <el-input
              v-model="search"
              placeholder="输入内容查询"
              @input="searchHandle(search)"
            />
          </div>
          <div class="btn-list">
            <el-button type="primary" @click="handleAdd">上传模型</el-button>
          </div>
        </div>
      </div>
      <div v-loading="viewLoading" class="content" :style="{ display: modelList.length > 0 && modelList.length < 3 ? 'flex' : 'grid' }">
        <template v-if="modelList.length > 0">
          <div
            v-for="item in modelList"
            :key="item.id"
            class="model-card"
            :style="{ width: modelList.length > 0 && modelList.length < 3 ? '392px' : 'auto' }"
            @click="goDetail(item)"
            @mouseleave="item.showMore = false"
          >
            <div
              :class="['modelBackground',item.openState === 1
                ? 'privateModelBackground'
                : item.openState === 2
                  ? 'publicModelBackground'
                  : 'shareModelBackground',
              ]"
            >
              <div
                class="model-icon"
              >
                {{
                  item.openState === 1
                    ? "私有"
                    : item.openState === 2
                      ? "公开"
                      : "共享"
                }}
              </div>
              <div class="tag-box">
                <div
                  v-for="(tag, index) in (item.label || '').split(',')"
                  v-show="tag !== ''"
                  :key="index"
                  class="tag"
                >
                  {{ tag }}
                </div>
              </div>
            </div>
            <div class="card-top">
              <div>
                <div class="title">
                  <div class="title-text">{{ item.name }}</div>
                  <div class="group" :style="{color:item.openState === 1? '#5A57F4' : item.openState === 2? '#165dff' : '#009463'}">
                    {{
                      groupOptions.find((i) => i.id === item.projectGroupId).name
                    }}
                  </div>
                  <el-icon
                    v-if="item.createBy === $store.state.user.name"
                    class="more"
                    @mouseenter="handleMore(item)"
                  ><MoreFilled /></el-icon>
                  <div v-show="item.showMore" ref="moreList" class="more-list">
                    <div
                      style="margin-bottom: 5px"
                      @click.stop="deleteModel(item)"
                    >
                      删除
                    </div>
                    <div @click.stop="handleEdit(item)">编辑</div>
                  </div>
                </div>

              </div>
              <div class="message">
                {{ item.description }}
              </div>
            </div>
            <div class="card-bottom">
              <span class="name">{{ item.createBy }}</span>
              <span class="time">更新时间： {{ item.createTime }}</span>
            </div>
          </div>
        </template>
        <div v-else style="text-align:center;padding-top:17px;width: 100%;">
          <img src="@/assets/icon/zanwu.png" alt="">
          <div style="padding-top:6px;color:#A8ABB2;font-size: 14px;">暂无数据</div>
        </div>
      </div>
      <div class="paginations">
        <div class="pagination">
          <div class="total">合计：{{ totalPage }}</div>
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            layout="sizes"
            :total="totalPage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
          <div class="jumper">
            <div>{{ pageNum }}/{{ pagenumber }}</div>
            <div class="jumper-line" />
            <el-pagination
              v-model:currentPage="pageNum"
              :page-size="pageSize"
              :page-count="pagenumber"
              :page-sizes="pageSizes"
              background
              layout="jumper"
              :total="totalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            background
            layout="prev, next"
            :total="totalPage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <template v-if="isShowDetailView">
      <div v-loading="detailViewLoading" class="model-detail">
        <div class="go-back" @click="goBack">
          <el-icon class="back-icon"><ArrowLeft /></el-icon>
          模型管理
        </div>
        <div class="info">
          <div class="content">

            <div style="margin-bottom: 8px">
              <span v-if="!modelDetailNameInput" class="model-name"> {{ modelEntity.name }}</span>
              <el-input v-else v-model="modelEntity.name" class="model-name-input" size="small" @keydown.enter="handleSubmitEditName(modelEntity)" @blur="handleBlurSubmit(modelEntity)" />
              <el-icon v-if="modelEntity.createBy === $store.state.user.name" class="edit-icon" @click="handleEditName(modelEntity)"><Edit /></el-icon>
              <span class="model-size">{{ fileSize }}</span>
              <div
                :class="[
                  'type',
                  modelEntity.openState === 1
                    ? 'type-green'
                    : modelEntity.openState === 2
                      ? 'type-purple'
                      : 'type-blue',
                ]"
              >
                {{
                  modelEntity.openState === 1
                    ? "私有"
                    : modelEntity.openState === 2
                      ? "公开"
                      : "共享"
                }}
              </div>
              <!-- <span @click="changeOpenState(modelEntity.openState)"> <el-icon v-show="isShowChangeOpenState" class="switch-icon"><Switch /></el-icon></span> -->
            </div>
            <!-- 描述 -->
            <div class="model-remark">{{ modelEntity.description }}</div>
            <div class="model-info">
              <span>创建时间：{{ modelEntity.createTime }}</span>
              <span><i>q</i>{{ modelEntity.updateBy || modelEntity.createBy }} 修改与
                {{ modelEntity.updateTime ||modelEntity.createTime }}</span>
              <span v-show="modelEntity.label"><el-icon class="tag-icon"><PriceTag /></el-icon>
                <span class="label" :title="modelEntity.label">              {{
                  modelEntity.label
                }}</span>
              </span>
              <span v-show="modelEntity.modelFormat">格式：{{ modelEntity.modelFormat }}</span>
            </div>
          </div>
        </div>
        <div class="version-list">
          <!--  -->
          <div class="left">
            <ai-file-list type="other" :create-by="modelEntity.createBy" :model-id="modelId" :model-version-name="modelVersionName" @confirmDirs="confirmfiles" />
            <div v-if="modelEntity.createBy === $store.state.user.name" class="add-version" @click="openVersionForm">
              <el-icon><Plus /></el-icon>
              新增版本</div>
          </div>
          <div class="right">
            <iframe
              :src="previewUrl"
              frameborder="0"
              style="width: 100%; height: 100%; border: none;"
            />
          </div>
          <div class="title">
            <div class="label">版本</div>
            <el-select
              v-model="modelVersionName"
              @change="calculateFileSize"
            >
              <el-option
                v-for="item in modelVersionOptions"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </div>
        </div>
      </div>
    </template>
    <!-- 上传模型 -->
    <div class="add-model">
      <el-dialog
        v-model="addModelVisible"
        :title="modelDrawerTitle"
        :before-close="cancelForm"
        width="644px"
        :close-on-click-modal="false"
      >
        <el-form
          ref="form"
          v-loading="addFormLoading"
          :model="form"
          :rules="rules"
          label-width="6em"
        >
          <el-form-item v-if="modelDrawerTitle === '上传模型'" label="">
            <!-- 进度条 -->
            <div class="progress">
              <div class="progress-item-active">
                <div v-if="form.stepIndex === 0" class="progress-icon">1</div>
                <div
                  v-else
                  class="progress-icon"
                  style="border-color: #0052d9; background-color: #fff"
                >
                  <el-icon><Check /></el-icon>
                </div>
                创建模型
              </div>
              <div
                class="progress-line"
                :style="{
                  backgroundColor: form.stepIndex > 0 ? '#0052D9' : '#DCDCDC',
                }"
              />
              <div
                :class="[
                  form.stepIndex > 0 ? 'progress-item-active' : 'progress-item',
                ]"
              >
                <div class="progress-icon">2</div>
                选择文件
              </div>
            </div>
          </el-form-item>
          <!-- 第一步创建模型 -->
          <template v-if="form.stepIndex === 0">
            <el-form-item label="模型名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入模型名称" />
            </el-form-item>
            <el-form-item label="模型描述">
              <el-input
                v-model="form.description"
                placeholder="请输入模型描述"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6 }"
                maxlength="200"
                show-word-limit
              />
            </el-form-item>
            <el-form-item label="标签" class="tag-form-item">
              <el-tag
                v-for="tag in dynamicTags"
                :key="tag"
                closable
                :disable-transitions="false"
                size="large"
                @close="handleTagClose(tag)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                v-if="inputVisible"
                ref="saveTagInput"
                v-model="inputValue"
                class="input-new-tag"
                size="small"
                @keyup.enter="handleInputConfirm"
                @blur="handleInputConfirm"
              />
              <el-button
                v-else
                class="button-new-tag"
                size="small"
                @click="showInput"
              >+ 新增标签</el-button>
            </el-form-item>
            <el-form-item label="公开性" prop="openState">
              <el-radio-group ref="radio" v-model="form.openState">
                <el-radio label="1">私有</el-radio>
                <el-radio label="3">共享</el-radio>
                <el-radio label="2">公开</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              v-if="form.openState === '3'"
              label=""
              prop="modelShares"
            >
              <el-select
                v-model="form.modelShares"
                multiple
                placeholder="共享至"
              >
                <el-option
                  v-for="item in groupUserOptions"
                  :key="item.id"
                  :label="item.name + '(' + item.nickname + ')'"
                  :value="item.id"
                  :disabled="item.id === currentGroupId"
                />
              </el-select>
            </el-form-item>
            <el-row>
              <el-col :span="8">
                <el-form-item label="模型镜像" prop="spaceName">
                  <el-select
                    v-model="form.spaceName"
                    collapse-tags
                    placeholder="请选择命名空间"
                    clearable
                    filterable
                    @change="handleSpaceChange"
                  >
                    <el-option
                      v-for="item in spaceNameOptions"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="" prop="imageName" label-width="0.5em">
                  <el-select
                    v-model="form.imageName"
                    collapse-tags
                    placeholder="请选择模型镜像"
                    clearable
                    filterable
                    @change="handleImageChange"
                  >
                    <el-option
                      v-for="item in imageOptions"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="" prop="imageVersion" label-width="0.5em">
                  <el-select
                    v-model="form.imageVersion"
                    collapse-tags
                    placeholder="请选择模型版本"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in versionOptions"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="模型格式" prop="modelFormat">
              <el-select
                v-model="form.modelFormat"
                collapse-tags
                placeholder="请选择模型格式"
              >
                <el-option
                  v-for="item in modelFormatOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </template>
          <!-- 第二步上传文件 -->
          <template v-if="form.stepIndex === 1">
            <el-form-item
              label="上传模型文件"
              prop="imageName"
              label-width="8em"
              class="is-required"
            >
              <div class="upload-box">
                <div class="choose-directory">
                  <el-button @click="chooseDirectory">选择上传目录</el-button>
                  <span
                    class="directory-info"
                  >上传到：根目录/{{ uploadDirectory }}</span>
                </div>
                <el-upload
                  ref="uploadFile"
                  drag
                  action=""
                  multiple
                  :file-list="fileList"
                  :on-change="handleChange"
                  :auto-upload="false"
                >
                  <svg-icon icon-class="upload-mode" class="upload-icon" />
                  <div class="el-upload__text">
                    将文件拖到此处，或<em>点击上传</em>
                  </div>
                </el-upload>
              </div>
            </el-form-item>
          </template>

          <div class="drawer__footer">
            <el-button @click="cancelForm">取 消</el-button>
            <el-button
              type="primary"
              :loading="addBtnLoading"
              @click="submitForm"
            >{{
              form.stepIndex === 0 && modelDrawerTitle === '上传模型'
                ? "下一步"
                : addBtnLoading
                  ? "提交中 ..."
                  : "确 定"
            }}</el-button>
          </div>
        </el-form></el-dialog>
    </div>
    <div class="add-version">
      <el-dialog
        v-model="addVersionVisible"
        title="新增模型版本"
        :before-close="cancelVersionForm"
        width="644px"
        :close-on-click-modal="false"
      >
        <el-form
          ref="versionForm"
          v-loading="uploadLoading"
          :model="versionForm"
          :rules="versionFormRules"
          label-width="6em"
        >
          <el-form-item label="">
            <!-- 进度条 -->
            <div class="progress">
              <div class="progress-item-active">
                <div v-if="versionForm.stepIndex === 0" class="progress-icon">1</div>
                <div
                  v-else
                  class="progress-icon"
                  style="border-color: #0052d9; background-color: #fff"
                >
                  <el-icon><Check /></el-icon>
                </div>
                创建模型
              </div>
              <div
                class="progress-line"
                :style="{
                  backgroundColor: versionForm.stepIndex > 0 ? '#0052D9' : '#DCDCDC',
                }"
              />
              <div
                :class="[
                  versionForm.stepIndex > 0 ? 'progress-item-active' : 'progress-item',
                ]"
              >
                <div class="progress-icon">2</div>
                选择文件
              </div>
            </div>
          </el-form-item>
          <!-- 第一步创建模型 -->
          <template v-if="versionForm.stepIndex === 0">
            <el-form-item label="模型名称">
              <!-- <el-input v-model="versionForm.name" placeholder="请输入模型名称" /> -->
              <span>{{ modelName }}</span>
            </el-form-item>
            <el-form-item label="基于版本" prop="baseOn">
              <el-select v-model="versionForm.baseOn">
                <el-option
                  v-for="item in modelVersionOptions"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="版本名称" prop="name">
              <el-input v-model="versionForm.name" placeholder="请输入版本名称" />
            </el-form-item>
          </template>
          <!-- 第二步上传文件 -->
          <template v-if="versionForm.stepIndex === 1">
            <el-form-item
              label="上传模型文件"
              prop="imageName"
              label-width="8em"
              class="is-required"
            >
              <div class="upload-box">
                <div class="choose-directory">
                  <el-button @click="chooseDirectory">选择上传目录</el-button>
                  <span
                    class="directory-info"
                  >上传到：根目录/{{ uploadDirectory }}</span>
                </div>
                <el-upload
                  ref="uploadVersionFile"
                  drag
                  action=""
                  multiple
                  :file-list="fileList"
                  :on-change="handleChange"
                  :auto-upload="false"
                >
                  <svg-icon icon-class="upload-mode" class="upload-icon" />
                  <div class="el-upload__text">
                    将文件拖到此处，或<em>点击上传</em>
                  </div>
                </el-upload>
              </div>
            </el-form-item>
          </template>

          <div class="drawer__footer">
            <el-button @click="cancelVersionForm">取 消</el-button>
            <el-button
              type="primary"
              :loading="addBtnLoading"
              @click="submitVersionForm"
            >{{
              versionForm.stepIndex === 0
                ? "下一步"
                : addBtnLoading
                  ? "提交中 ..."
                  : "确 定"
            }}</el-button>
          </div>
        </el-form></el-dialog>
    </div>
    <ai-choice-file :visible="choiceFileVisible" type="dir" :model-id="modelId" :model-version-name="modelVersionName" @confirmDirs="confirmDirs" />
  </div>
</template>

<script>
import {
  getModelFormat,
  getUserProjectGroup,
  getAllNameSpace,
  getAllImageByNamespace,
  getArtifactVersions,
  getModelList,
  uploadModelFile,
  updateModel,
  deleteModel,
  getModelDetail,
  patchOpenState,
  uploadFile,
  createModelVersion,
  getModelShareInfo,
  calculateFileSize,
  patchModelByName,
  getAllProjectGroup,
  deleteModelVersion,
  queryFileOrDirExist
} from '@/api/ai.js'
import { ElMessageBox, ElMessage } from 'element-plus'
import AiChoiceFile from '../components/AiChoiceFile.vue'
import AiFileList from '../components/AiFileList.vue'
import { login } from '@/api/preview.js'
import axios from 'axios'
const validateInput = (rule, value, callback) => {
  const regex = /^[A-Za-z0-9]{1,20}$/ // 只允许英文和数字
  if (!value) {
    return callback(new Error('输入不能为空'))
  }
  if (!regex.test(value)) {
    return callback(new Error('只能输入英文和数字,长度1-20位'))
  }
  callback()
}
export default {
  components: {
    AiChoiceFile,
    AiFileList
  },
  data() {
    return {
      modelList: [],
      activeTab: '1',
      groupId: '',
      groupOptions: [],
      groupUserOptions: [],
      groupUserOptionsCopy: [],
      search: '',
      timer: null,
      tagList: [],
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50],
      pagenumber: 0,
      totalPage: 0,
      modelDrawerTitle: '上传模型',
      addModelVisible: false,
      form: {
        name: '',
        description: '',
        openState: '1',
        modelShares: [],
        spaceName: '',
        imageName: '',
        modelFormat: '',
        imageVersion: '',
        stepIndex: 0
      },
      rules: {
        name: [
          { required: true, message: '请输入模型名称', trigger: 'blur' },
          { validator: (rule, value, callback) => {
            // 中英文、下划线、短横杠、点, 1-100个字符, 以中英文、数字开头
            const regex = /^[\u4E00-\u9FA5a-zA-Z0-9][\u4E00-\u9FA5a-zA-Z0-9_\-\.]{0,99}$/
            if (regex.test(this.form.name)) {
              return callback()
            } else if (!value) {
              return callback(new Error('输入不能为空'))
            } else if (value.length > 100) {
              return callback(new Error('模型名称长度在1-100个字符以内'))
            } else {
              return callback(new Error('模型名称只包含中英文、下划线、短横杠、点，且以中英文、数字开头'))
            }
          }, trigger: 'blur' }
        ],
        openState: [
          { required: true, message: '请选择公开性', trigger: 'change' }
        ]
      },
      versionFormRules: {
        name: [
          { validator: validateInput, trigger: 'blur' },
          { required: true, message: '请输入版本名称', trigger: 'blur' }],
        baseOn: [{ required: true, message: '请选择基于版本', trigger: 'change' }]
      },
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      spaceNameOptions: [],
      imageOptions: [],
      versionOptions: [],
      addFormLoading: false,
      // 模型格式
      modelFormatOptions: [],
      uploadDirectory: '',
      addBtnLoading: false,
      viewLoading: false,
      isShowDetailView: false,
      id: '',
      modelEntity: {
        name: '模型1',
        description: '我我我我1嗡嗡嗡嗡嗡嗡我我我我我我我我',
        openState: '1',
        createTime: '2024-01-01 00:00:00',
        updateBy: 'admin',
        createBy: 'admin',
        updateTime: '2024-01-01 00:00:00',
        label: '123',
        modelFormat: 'tensorflow'
      },
      detailViewLoading: false,
      modelId: '',
      modelName: '',
      modelVersionName: '',
      choiceFileVisible: false,
      fileList: [],
      versionForm: {
        name: '',
        baseOn: '',
        stepIndex: 0
      },
      addVersionVisible: false,
      previewUrl: '',
      token: '',
      modelVersionOptions: [],
      fileSize: '0B',
      modelDetailNameInput: false,
      versionId: '',
      uploadLoading: false
    }
  },
  computed: {
    isShowChangeOpenState() {
      return this.modelEntity.createBy === this.$store.state.user.name
    },
    modelReset() {
      return this.$store.state.ai.modelCreate
    }

  },
  watch: {
    modelReset() {
      this.getData()
    }
  },
  created() {
    login().then(res => {
      this.token = res.data
      this.$store.commit('user/SET_FILE_PREVIEW_TOKEN', this.token)
    })
  },
  mounted() {
    this.addFormLoading = true
    Promise.all([
      // 查命名空间
      getAllNameSpace(),
      // 查模型格式
      getModelFormat(),
      getUserProjectGroup(),
      getAllProjectGroup()
    ]).then(([spaceNameRes, modelFormat, groupRes, allGroupRes]) => {
      this.addFormLoading = false
      this.spaceNameOptions = spaceNameRes.data
      this.modelFormatOptions = modelFormat
      this.groupUserOptions = groupRes.data.content
      // 留一个groupUserOptions的副本
      this.groupUserOptionsCopy = Object.assign([], this.groupUserOptions)
      this.groupId = groupRes.data.content[0].id
      this.groupOptions = allGroupRes.data.content
      this.getData()
    })
  },
  methods: {
    // 查版本文件大小
    calculateFileSize() {
      calculateFileSize(this.modelId, this.modelVersionName).then(response => {
        if (response.meta.status === 200) {
          this.fileSize = this.formatFileSize(response.data)
        }
      })
    },
    // 字节转换kb、mb、gb
    formatFileSize(size) {
      if (size < 1024) {
        return size + 'B'
      } else if (size < 1048576) {
        return (size / 1024).toFixed(2) + 'KB'
      } else if (size < 1073741824) {
        return (size / 1048576).toFixed(2) + 'MB'
      } else {
        return (size / 1073741824).toFixed(2) + 'GB'
      }
    },
    // 点击后生成文件链接，使用iframe预览
    confirmfiles(dir) {
      axios.get('./json/aiPreviewUrl.json').then(res => {
        this.previewUrl = res.data.url + '/file/' + this.modelEntity.createBy + '/' + this.modelId + '/' + this.modelVersionName + '/' + dir + '?token=' + this.token
      })
    },
    goDetail({ id }) {
      this.isShowDetailView = true
      this.detailViewLoading = true
      this.modelId = id

      this.modelVersionName = 'v1'
      this.calculateFileSize()
      getModelDetail(id).then((res) => {
        this.modelEntity = res.data.modelEntity
        this.modelName = this.modelEntity.name
        this.modelVersionOptions = res.data.modelVersionEntities
        this.modelVersionName = this.modelVersionOptions[0].name
        console.log(this.modelVersionOptions, '版本')
      }).finally(() => {
        this.detailViewLoading = false
      })
    },
    goBack() {
      this.isShowDetailView = false
      this.modelDetailNameInput = false
      this.detailViewLoading = false
      this.previewUrl = ''
      this.getData()
    },
    // 切换tab
    changeTab(tab) {
      this.pageNum = 1
      this.pageSize = 10
      this.activeTab = tab
      this.getData()
    },
    // 组变化时重新获取数据
    handleGroupChange(val) {
      if (val) {
        this.getData()
      }
    },
    // 获取模型列表
    getData() {
      this.viewLoading = true
      getModelList(
        this.pageNum,
        this.pageSize,
        this.search,
        this.groupId,
        this.activeTab
      ).then((res) => {
        this.viewLoading = false
        this.modelList = res.data.content.map((item) => {
          return {
            ...item,
            showMore: false
          }
        })
        this.totalPage = res.data.totalElements
        this.pagenumber =
          this.totalPage === 0 ? 1 : Math.ceil(this.totalPage / this.pageSize)
      })
    },
    searchHandle(val) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.pageNum = 1
        this.search = val
        this.$nextTick(() => {
          this.getData()
        })
      }, 500)
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getData()
    },
    handleEdit(item) {
      this.addModelVisible = true
      this.modelDrawerTitle = '编辑模型基本信息'
      this.groupUserOptions = Object.assign([], this.groupUserOptionsCopy)
      this.currentGroupId = item.projectGroupId
      // 查共享项目组

      getModelShareInfo(item.id).then((res) => {
        this.form.modelShares = res.data
        if (this.form.modelShares.length === 0) {
          this.form.modelShares = [Number(this.currentGroupId)]
        }
        // 查 this.form.modelShares中有没有不属于groupUserOptions的id
        const modelShares = this.form.modelShares.filter(id => {
          return this.groupUserOptions.findIndex(item => item.id === id) === -1
        })
        console.log(modelShares, this.groupUserOptions, 'modelShares')
        // 如果有不属于groupUserOptions的id，则在groupOptions中找到并添加到groupUserOptions中
        if (modelShares.length > 0) {
          const newGroupOptions = this.groupOptions.filter(item => {
            return modelShares.includes(item.id)
          })
          this.groupUserOptions.push(...newGroupOptions)
        }
      })
      // 查镜像
      if (item.imageRepository) {
        this.handleSpaceChange(item.imageRepository)
        // 查镜像版本
        if (item.imageName) {
          getArtifactVersions('', '', item.imageRepository + '/' + item.imageName).then(
            (res) => {
              this.versionOptions = res.data
              this.addFormLoading = false
            }
          )
        }
      }
      this.form = {
        name: item.name,
        description: item.description,
        openState: item.openState.toString(),
        modelShares: item.modelShares ? item.modelShares.split(',') : [],
        spaceName: item.imageRepository,
        imageName: item.imageName,
        imageVersion: item.imageVersion,
        modelFormat: item.modelFormat,
        stepIndex: 0,
        id: item.id
      }
      this.dynamicTags = item.label ? item.label.split(',') : []
    },
    handleAdd() {
      this.$store.dispatch('core/openApp', 2154413087280450)
      this.$store.commit('ai/SET_UPLOAD_PROJECT_ID', this.groupId)
      // this.groupUserOptions = Object.assign([], this.groupUserOptionsCopy)
      // this.addModelVisible = true
      // this.currentGroupId = Number(this.groupId)
      // this.form.modelShares = [this.currentGroupId]
      // this.modelDrawerTitle = '上传模型'
    },
    // 添加标签相关逻辑
    handleTagClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm() {
      const inputValue = this.inputValue
      if (inputValue) {
        if (inputValue.length <= 20) {
          this.dynamicTags.push(inputValue)
          this.inputVisible = false
          this.inputValue = ''
        } else {
          return ElMessage.warning('标签长度不能超过20个字符')
        }
      } else {
        this.inputVisible = false
        this.inputValue = ''
      }
    },
    // 命名空间变化时
    handleSpaceChange(val) {
      this.versionOptions = []
      this.imageOptions = []
      this.form.imageName = ''
      this.form.imageVersion = ''
      if (val) {
        // 查镜像

        this.addFormLoading = true
        getAllImageByNamespace(val).then((res) => {
          this.imageOptions = res.data
          this.addFormLoading = false
        })
      } else {
        this.imageOptions = []
      }
    },
    // 镜像变化时
    handleImageChange(val) {
      this.versionOptions = []
      this.form.imageVersion = ''
      if (val) {
        // 查版本
        this.addFormLoading = true

        getArtifactVersions('', '', this.form.spaceName + '/' + val).then(
          (res) => {
            this.versionOptions = res.data
            this.addFormLoading = false
          }
        )
      } else {
        this.versionOptions = []
      }
    },
    // 选择目录
    chooseDirectory(e) {
      this.choiceFileVisible = true
    },
    // 提交上传模型
    submitForm() {
      /** 如果 stepIndex为0则创建模型执行下一步*/
      if (this.modelDrawerTitle === '上传模型') {
        if (this.form.stepIndex === 0) {
          this.createModel()
        } else {
          // 判断是否有文件
          // console.log(this.fileList, '文件列表')
          if (this.fileList.length === 0) {
            return ElMessage.warning('请上传文件')
          }
          this.addModelVisible = false
          this.form = {
            name: '',
            description: '',
            openState: '1',
            modelShares: [],
            spaceName: '',
            imageName: '',
            modelFormat: '',
            stepIndex: 0
          }
          this.dynamicTags = []
          this.fileList = []
          this.uploadDirectory = ''
          this.$refs.form.resetFields()
          this.getData()
        }
      } else {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const data = {
              name: this.form.name,
              description: this.form.description,
              openState: this.form.openState,
              imageRepository: this.form.spaceName,
              imageName: this.form.imageName,
              imageVersion: this.form.imageVersion,
              modelFormat: this.form.modelFormat,
              projectGroupId: this.groupId,
              id: this.form.id
            }
            const params = {
              labels: this.dynamicTags.join(','),
              modelShares: this.form.modelShares.join(',')
            }
            this.addBtnLoading = true
            this.addFormLoading = true
            updateModel(data, params).then((res) => {
              this.addModelVisible = false
              this.$message.success('编辑成功')

              this.getData()
            }).finally(() => {
              this.addBtnLoading = false
              this.addFormLoading = false
            })
          }
        })
      }
    },
    createModel() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 创建模型
          this.addBtnLoading = true
          this.addFormLoading = true
          const data = {
            name: this.form.name,
            description: this.form.description,
            openState: this.form.openState,
            imageRepository: this.form.spaceName,
            imageName: this.form.imageName,
            imageVersion: this.form.imageVersion,
            modelFormat: this.form.modelFormat,
            projectGroupId: this.groupId
          }
          const params = {
            labels: this.dynamicTags.join(','),
            modelShares: this.form.modelShares.join(',')
          }
          uploadModelFile(data, params).then((res) => {
            this.modelId = res.data.modelId
            this.modelVersionName = res.data.modelVersionName
            this.form.stepIndex = 1
          }).finally(() => {
            this.addBtnLoading = false
            this.addFormLoading = false
          })
        }
      })
    },
    cancelForm() {
      this.addModelVisible = false
      if (this.form.stepIndex === 1) {
        // 删除当前模型
        deleteModel(this.modelId).then((res) => {
          this.getData()
        })
      }
      this.imageOptions = []
      this.versionOptions = []
      this.form = {
        name: '',
        description: '',
        openState: '1',
        modelShares: [],
        spaceName: '',
        imageName: '',
        modelFormat: '',
        stepIndex: 0
      }
      this.currentGroupId = ''
      this.dynamicTags = []
      this.fileList = []
      this.uploadDirectory = ''
      this.addFormLoading = false
      this.addBtnLoading = false
      this.$refs.form.resetFields()
    },
    handleMore(item) {
      // 判断是否是当前用户的模型，是则可编辑
      item.showMore = true
    },
    deleteModel(item) {
      ElMessageBox.confirm('确定要删除这个模型吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          // 执行删除操作
          // 在这里调用实际的删除逻辑，例如发送请求到服务器
          deleteModel(item.id).then((res) => {
            if (res.meta.status === 200) {
              const totalPage = Math.ceil((this.totalPage - 1) / this.pageSize)
              this.pageNum = this.pageNum > totalPage ? totalPage : this.pageNum
              this.pageNum = this.pageNum < 1 ? 1 : this.pageNum
              ElMessage.success('删除成功')
              this.getData()
            }
          })
        })
        .catch(() => {
          ElMessage.info('已取消删除')
        })
    },
    changeOpenState(openState) {
      ++openState
      if (openState > 3) {
        openState = 1
      }
      patchOpenState(this.modelEntity.id, openState).then((res) => {
        this.goDetail(this.modelEntity)
      })
    },
    confirmDirs(dir, type) {
      this.uploadDirectory = dir
      this.choiceFileVisible = false
    },
    handleExceed() {},
    handleChange(file, fileList) {
      this.fileList = fileList
      this.uploadFile(fileList)
    },
    uploadFile(fileList) {
      if (fileList.length === 0) {
        return
      }
      // 未上传的文件
      const notUploadFiles = fileList.filter((file) => {
        return file.status !== 'success'
      })
      this.fileList.forEach((file) => {
        file.status = 'success'
      })
      if (notUploadFiles.length === 0) {
        return
      }
      this.addFormLoading = true
      this.uploadLoading = true
      this.concurRequest(notUploadFiles, 1).then((res) => {
        this.uploadLoading = false
        this.addFormLoading = false
      })
    },
    concurRequest(files, maxNum) {
      return new Promise(resolve => {
        if (files.length === 0) {
          resolve([])
          return
        }
        const results = []
        let index = 0
        const that = this
        async function request() {
          if (index >= files.length) {
            return
          }
          const i = index
          const file = files[index]
          index++
          const params = {
            modelId: that.modelId,
            modelVersionName: that.versionForm.name || that.modelVersionName,
            dirPath: that.uploadDirectory + '/' + file.name
          }
          const exist = await queryFileOrDirExist(params)
          if (exist.data) {
            // ElMessageBox打开后查找el-overlay is-message-box元素，只保留z-index最小的元素

            const overlay = document.querySelector('.el-overlay.is-message-box')
            if (overlay) {
              that.$nextTick(() => {
                const overlays = document.getElementsByClassName('el-overlay is-message-box')
                console.log(overlays, 'overlays')
                // 删除除z-index最小的元素外的所有元素
                for (let i = 0; i < overlays.length; i++) {
                  if (overlays[i] !== overlay) {
                    overlays[i].style.backgroundColor = 'rgba(0,0,0,0)'
                  }
                }
              })
            }

            ElMessageBox.confirm(file.name + '文件已存在，是否覆盖？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '跳过'
            }).then(async() => {
              const files = that.fileList.filter(item => item.name === file.name)
              if (files.length > 1) {
                const index = that.fileList.findIndex(item => item.name === file.name)
                that.fileList.splice(index, 1)
              }
              const formData = new FormData()
              formData.append('file', file.raw)
              const data = {
                modelId: that.modelId,
                modelVersionName: that.versionForm.name || that.modelVersionName,
                dirPath: that.uploadDirectory
              }
              const resp = await uploadFile(formData, data)
              results[i] = resp
            }).catch(() => {
              // 从fileList中找到uid === file.uid的对象，将其删掉
              const index = that.fileList.findIndex(item => item.uid === file.uid)
              that.fileList.splice(index, 1)
            })
          } else {
            const formData = new FormData()
            formData.append('file', file.raw)
            const data = {
              modelId: that.modelId,
              modelVersionName: that.versionForm.name || that.modelVersionName,
              dirPath: that.uploadDirectory
            }
            const resp = await uploadFile(formData, data)
            results[i] = resp
          }
          // results的长度等于files的长度并且所有值都不为空时，才resolve。转换成json判断
          const jsonResults = JSON.parse(JSON.stringify(results))
          // 判断长度并且不为空字符串
          if (jsonResults.every(item => item !== '' && item !== null)) {
            resolve(results)
          }
          request()
        }
        for (let index = 0; index < maxNum; index++) {
          request()
        }
      })
    },
    openVersionForm() {
      this.addVersionVisible = true
      this.$nextTick(() => {
        this.$refs.versionForm.resetFields()
      })
    },
    submitVersionForm() {
      if (this.versionForm.stepIndex === 0) {
        this.$refs.versionForm.validate((valid) => {
          if (valid) {
            const data = {
              baseOn: this.versionForm.baseOn,
              modelId: this.modelId,
              name: this.versionForm.name
            }
            this.createModelVersion(data)
          }
        })
      } else {
        if (this.fileList.length === 0) {
          return ElMessage.warning('请选择文件')
        }
        this.versionForm = {
          name: '',
          baseOn: '',
          stepIndex: 0
        }
        this.fileList = []
        this.$refs.versionForm.resetFields()
        this.uploadDirectory = ''
        this.modelVersionName = 'v1'
        this.addVersionVisible = false
        this.goDetail(this.modelEntity)
      }
    },
    cancelVersionForm() {
      if (this.versionForm.stepIndex === 1) {
        deleteModelVersion(this.versionId, this.modelId).then((res) => {
          this.getData()
        })
      }

      this.versionForm = {
        name: '',
        baseOn: '',
        stepIndex: 0
      }
      this.modelVersionName = 'v1'
      this.fileList = []
      this.$refs.versionForm.resetFields()
      this.uploadDirectory = ''
      this.addVersionVisible = false
      this.uploadLoading = false
    },
    // 新增模型版本
    createModelVersion(data) {
      createModelVersion(data).then((res) => {
        this.versionId = res.data
        this.versionForm.stepIndex = 1
        this.modelVersionName = this.versionForm.name
        // this.modelVersionName = this.versionForm.name
      })
    },
    // 修改模型名称
    handleEditName() {
      this.modelDetailNameInput = true
    },
    handleSubmitEditName({ id, name }) {
      patchModelByName(id, name).then((res) => {
        this.modelDetailNameInput = false
        this.goDetail(this.modelEntity)
      })
    },
    handleBlurSubmit() {
      this.modelDetailNameInput = false
      this.goDetail(this.modelEntity)
    }
  }
}
</script>

<style scoped lang="less">
.model-view {
  font-family: PingFang SC, PingFang SC;
  min-width: 1200px;
  height: 100%;
  :deep(.el-upload-list){
    .el-icon--close{
        display: none;
    }
  }
  :deep(.el-dialog .el-dialog__body){
    max-height: 600px;
    overflow: auto;
  }
  .tag-form-item{
    :deep(.el-form-item__content){
      row-gap:8px;

    }
    :deep(.el-tag__content){
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }
  }
  .model-list {
    :deep(.el-button--default) {
      color: #fff;
    }
    height: 100%;
    .top-bar {
      position: relative;
      display: flex;
      height: 68px;
      padding: 16px 24px 16px 40px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
      margin-bottom: 16px;
      .tablist {
        display: flex;
        margin-right: 40px;
        .tab {
          padding: 5px 24px 7px 24px;
          background-color: #fff;
          color: #303133;
          cursor: pointer;
          border-radius: 2px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
        .active-tab {
          color: #165dff;
          background-color: #f2f3f5;
        }
      }
      .select {
        .el-select {
          width: 260px;
        }
        :deep(.el-input--suffix .el-input__inner) {
          height: 36px;
          background-color: #f2f3f5;
        }
      }
      .right {
        position: absolute;
        top: 16px;
        right: 40px;
        display: flex;
      }
      .search {
        margin-right: 40px;
        .el-input {
          width: 260px;
        }
        ::v-deep .el-input--suffix .el-input__inner {
          height: 36px;
          background-color: #f2f3f5;
        }
      }
    }
    .content {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(392px, 1fr));
      grid-template-rows: auto;
      grid-auto-rows: minmax(300px, auto);
      grid-gap: 16px;

      padding: 0 24px;
      min-height: 300px;
      max-height: calc(100% - 168px);
      overflow: auto;
      .model-card {
        // width: 392px;
        flex-wrap: wrap;
        height: 300px;
        background: #ffffff;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #dcdcdc;
        cursor: pointer;
        &:hover {
          box-shadow: 2px 6px 12px 0px rgba(0, 0, 0, 0.16);
        }
        .modelBackground{
            height: 108px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 6px 7px;
            .model-icon{
              float: right;
              width: 42px;
              height: 22px;
              border-radius: 2px 2px 2px 2px;
              color: #fff;
              text-align: center;
            }
           .tag-box {
             display: flex;
             gap: 8px;
             overflow: auto;
             align-items: flex-end;
            height: 100%;
            flex-wrap: wrap;
             .tag {
               background-color: #fff;
               border: 1px solid #DCDCDC;
               border-radius: 3px;
               font-weight: 400;
               font-size: 12px;
               color: #303133;
               line-height: 20px;
               padding: 0 4px;
               max-width: 160px;
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
             }
           }
          }
          .publicModelBackground{
            background-image: url(../assets/publicModel.png);
            .model-icon{
              background-color: #165dff;
            }
          }
          .privateModelBackground{
            background-image: url(../assets/privateModel.png);
            .model-icon{
              background-color: #5A57F4;
            }
          }
          .shareModelBackground{
            background-image: url(../assets/sharedModel.png);
            .model-icon{
              background-color: #009463;
            }
          }
        .card-top {
          height: 120px;
          padding: 24px 16px 0 16px;

          .title {
            position: relative;
            display: flex;
            font-weight: bold;
            font-size: 16px;
            color: #303133;
            line-height: 24px;
            .title-text {
              width: 100px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .group {
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              margin-left: 16px;
            }
            .more {
              position: absolute;
              font-size: 18px;
              transform: rotate(90deg);
              margin-left: 187px;
              top: 0;
              right: 0;
            }
            .more-list {
              position: absolute;
              right: 20px;
              top: 0;
              width: 59px;
              height: 70px;
              background: #ffffff;
              box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12),
                0px 4px 5px 0px rgba(0, 0, 0, 0.08),
                0px 1px 10px 0px rgba(0, 0, 0, 0.05);
              border-radius: 6px 6px 6px 6px;
              border: 1px solid #dcdcdc;
              font-weight: 400;
              font-size: 14px;
              color: #303133;
              line-height: 22px;
              text-align: center;
              padding: 9px 14px;
            }
          }

          .message {
            // width: 352px;
            height: 66px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #606266;
            line-height: 22px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        .card-bottom {
          height: 60px;
          padding: 0 24px;
          border-top: 1px solid #dcdcdc;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 400;
          .name {
            font-size: 14px;
            color: #303133;
            line-height: 22px;
          }
          .time {
            font-size: 12px;
            color: #909399;
            line-height: 20px;
          }
        }
      }
    }
    .paginations {
      padding: 12px 24px 24px 0;
      float: right;
    }
  }
  .add-model ,.add-version{
    .el-select {
      flex:1
    }

    .progress {
      display: flex;
      align-items: center;
      .progress-item-active {
        color: rgba(0, 0, 0, 0.9);
        .progress-icon {
          display: inline-block;
          margin-right: 16px;
          border-radius: 50%;
          line-height: 24px;
          text-align: center;
          color: #fff;
          width: 24px;
          height: 24px;
          background: #0052d9;
          border: 1px solid #0052d9;
          .el-icon {
            transform: translateX(-2px) translateY(-2px);
            color: #0052d9 !important;
          }
        }
      }
      .progress-item {
        color: rgba(0, 0, 0, 0.4);
        .progress-icon {
          display: inline-block;
          margin-right: 16px;
          border-radius: 50%;
          line-height: 24px;
          text-align: center;
          color: rgba(0, 0, 0, 0.4);
          width: 24px;
          height: 24px;
          background: #fff;
          border: 1px solid rgba(0, 0, 0, 0.4);
        }
      }
      .progress-line {
        margin: 0 16px;
        width: 164px;
        height: 2px;
        background: #dcdcdc;
      }
    }
    :deep(.el-dialog) {
      max-height: 800px;
    }
    .el-tag {
      margin-right: 10px;
    }
    .button-new-tag {
      margin-left: 10px;
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .input-new-tag {
      width: 90px;
      margin-left: 10px;
      vertical-align: bottom;
    }
    .choose-directory {
      height: 120px;
      padding: 12px 10px;
      .directory-info {
        font-weight: 400;
        font-size: 14px;
        color: #606266;
        line-height: 22px;
        margin-left: 16px;
      }
    }
    .upload-box {
      border: 1px dashed #dcdcdc;
      border-radius: 6px;
    }
    :deep(.el-upload-dragger) {
      border: none;
      border-radius: 0 0 6px 6px;
      width: 450px;
      height: 175px;
      .upload-icon {
        width: 52px;
        height: 68px;
      }
    }
    .drawer__footer {
      text-align: right;
    }
    :deep(.el-button--default) {
      color: #4e5969;
      background-color: #f2f3f5;
      border: none;
    }
    :deep(.el-button--primary) {
      color: #fff;
    }

  }
  :deep(.el-upload-list){
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 150px;
    }
  .model-detail {
    font-family: PingFang SC, PingFang SC;
    padding: 16px 20px;

    .model-name-input{
      width: 150px;
    }
    :deep(.el-button--primary) {
      color: #fff !important;
      background-color: #165dff !important;
      border-color: #165dff !important;
    }
    .edit-icon{
      font-size: 13px;
      color: #909399;
      margin: 0 8px;
    }
    .tag-icon{
      font-size: 14px;
      color: #909399;
      transform: rotate(45deg);
      margin-right: 4px;
    }
    .go-back{
      font-size: 16px;
      margin-bottom: 16px;
      cursor: pointer;
      .back-icon{
        font-size: 16px;
        transform: translateY(2px);
      }
    }
    .info {
      // height: 186px;
      background-color: #fff;
      margin-bottom: 32px;

      .title {
        font-weight: 500;
        font-size: 16px;
        color: #1d2129;
        line-height: 24px;
        &::before {
          content: "";
          display: inline-block;
          width: 3px;
          height: 16px;
          background-color: #165dff;
          margin-right: 6px;
          transform: translateY(3px);
        }
      }
      .content {
        padding: 24px;
        border: 1px solid #e3e4e8;
        position: relative;
        .download-btn {
          position: absolute;
          top: 24px;
          right: 50px;
        }
        .switch-icon{
          font-size: 16px;
          transform: rotate(90deg) translateX(2px);
          color: #8D98AE;
          margin-left: 16px;
          cursor: pointer;
          &:hover{
            color: #165dff;
          }
        }
        .model-name {
          font-weight: 500;
          font-size: 16px;
          color: #303133;
          line-height: 24px;
        }
        .model-size {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #606266;
          line-height: 22px;
        }
        .type {
          display: inline-block;
          margin-left: 24px;
          border-radius: 24px 24px 24px 24px;
          text-align: center;
          width: 52px;
          height: 22px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
        }
        //蓝
        .type-blue {
          background-color: #e5f1ff;
          color: #165dff;
        }
        //绿
        .type-green {
          background: rgba(0, 148, 99, 0.1);
          color: #009463;
        }
        //紫
        .type-purple {
          background: #f1f1ff;
          color: #5a57f4;
        }
        .model-remark {
          font-weight: 400;
          font-size: 16px;
          color: #606266;
          line-height: 24px;
          margin-bottom: 24px;
          height: 48px;
          //三行溢出隐藏
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          // transform: translateY(5px);
          vertical-align: middle;
        }
        .model-info {
          font-weight: 400;
          font-size: 14px;
          color: #909399;
          line-height: 22px;
          .label{
               //单行溢出隐藏
          display: inline-block;
          max-width: 260px;
          // -webkit-line-clamp: 1;
          // -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          // transform: translateY(5px);
          vertical-align: middle;
          }
          span {
            margin-right: 74px;
            &:nth-child(2) > i {
              display: inline-block;
              border-radius: 50%;
              width: 16px;
              height: 16px;
              background-color: #8d98ae;
              color: #fff;
              line-height: 13px;
              font-size: 14px;
              // text-align: center;
              padding: 0 2px;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .version-list {
      background-color: #f5f5f5;
      margin-bottom: 32px;
      padding: 33px 16px;
      display: flex;
      gap: 16px;
      position: relative;
      .title {
        .el-select {
          border: 1px solid #165dff;
        }

        :deep(.el-input__inner) {
          height: 31px;
          line-height: 31px;
          border-radius: 0;
        }
        :deep(.el-icon) {
          color: #165dff !important;
        }
      }

      .left {
        width: 20%;
        min-width: 304px;
        height: 530px;
        border-radius: 4px;
        border: 1px solid #e5e9f2;
        background-color: #fff;
        .add-version {
          width: 140px;
          height: 32px;
          border-radius: 3px 3px 3px 3px;
          border: 1px solid #165DFF;
          color: #165DFF;
          text-align: center;
          line-height: 32px;
          font-size: 14px;
          cursor: pointer;
          margin: 0 auto;
          .el-icon{
            font-size: 12px;
            color: #165DFF;
          }
        }
      }
      .right {
        flex: 1;
        width: 1210px;
        height: 530px;
        border-radius: 4px;
        border: 1px solid #e5e9f2;
        background-color: #fff;
      }
      .title {
        position: absolute;
        display: flex;
        top: -15px;
        left: 20px;
        gap: 8px;
        .label {
          width: 59px;
          height: 32px;
          background: #ffffff;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #165dff;
          font-weight: 400;
          font-size: 14px;
          color: #165dff;
          line-height: 22px;
          padding: 5px 10px 5px 10px;
          text-align: center;
        }
        :deep(.el-select) {
          width: 132px;
          .el-input__inner {
            border-color: #165dff;
            color: #165dff;
          }
          .el-select__caret {
            color: #165dff;
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>
